import axios from 'axios';

export const state = {
    device: null
}


export const actions = {
    device_details ({ commit }) {
        const site_id = sessionStorage.getItem('site_id')
        axios({
            method: 'GET',
            url: '/api/v1/app/stp-automation/device',
            params: {
                site_id: site_id
            }
        })
        .then(async(response) => {
            if(response.data.errCode == -1){
                commit('SET_CURRENT_DEVICE', response.data.data[0])
                return response.data.data[0];    
            }     
        }).catch((err) => {
            console.log(err)
        })
    }
};


export const getters = {
    get_device_details(state){
       return state.device
    }
}

export const mutations = {
    SET_CURRENT_DEVICE(state, newValue) {
        state.device = newValue
    }
}

