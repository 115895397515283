//import store from "@/state/store";
import stpAutomationRoutes from './app/stp-automation/router/index'

export default [
  {
    path: "/",
    name: "login",
    component: () => import("./authentication/login"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        next({name: 'login'})
      }
    }
  },
  ...stpAutomationRoutes,
];