const routes = [
	{
		path: "/stp-automation",
		name: "stp-automation-dashboard",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/dashboard"),
	},
	{
		path: "/stp-automation/plant-monitoring",
		name: "stp-automation-plant-monitoring",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/monitoring"),
	},
	{
		path: "/stp-automation/camera-monitoring",
		name: "stp-automation-camera-monitoring",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/camera-monitoring"),
	},
	{
		path: "/stp-automation/plant-process",
		name: "stp-automation-process",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/process"),
	},
	{
		path: "/stp-automation/plant-operation-logs",
		name: "stp-automation-plant-operation-logs",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/plant-operation-logs"),
	},
	{
		path: "/stp-automation/remote-operation-logs",
		name: "stp-automation-remote-operation-logs",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/remote-operation-logs"),
	},
	{
		path: "/stp-automation/parameter-report",
		name: "stp-automation-parameter-report",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/parameter-report"),
	},
	{
		path: "/stp-automation/graphical-trend",
		name: "stp-automation-graphical-trend",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/graphical-trend"),
	},
	{
		path: "/stp-automation/alerts-report",
		name: "stp-automation-alerts-report",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/alert-report"),
	},
	{
		path: "/stp-automation/delay-offline-report",
		name: "stp-automation-delay-offline-report",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/delay-offline-report"),
	},
	{
		path: "/stp-automation/profile-settings",
		name: "stp-automation-profile-settings",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/profile-settings"),
	},
	{
		path: "/stp-automation/plant-settings",
		name: "stp-automation-plant-settings",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/plant-settings"),
	},
	{
		path: "/stp-automation/help-center",
		name: "stp-automation-help-center",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module', 'stp-automation')){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/plant/help-center"),
	},
	{
		path: "/stp-automation/vendor",
		name: "stp-automation-vendor",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module') == 'stp-automation' && sessionStorage.getItem('role') == 'vendor'){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/vendor/dashboard")
	},
	{
		path: "/stp-automation/vendor/tenants",
		name: "stp-automation-tenants",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module') == 'stp-automation' && sessionStorage.getItem('role') == 'vendor'){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/vendor/tenant")
	},
	{
		path: "/stp-automation/vendor/create-tenant",
		name: "stp-automation-vendor/create-tenant",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module') == 'stp-automation' && sessionStorage.getItem('role') == 'vendor'){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/vendor/create-tenant")
	},
	{
		path: "/stp-automation/vendor/devices",
		name: "stp-automation-devices",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module') == 'stp-automation' && sessionStorage.getItem('role') == 'vendor'){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/vendor/device")
	},
	{
		path: "/stp-automation/vendor/create-device",
		name: "stp-automation-vendor/create-device",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if(sessionStorage.getItem('module') == 'stp-automation' && sessionStorage.getItem('role') == 'vendor'){
					next()
				}
				else{
					sessionStorage.clear()
					next({name: 'logout'})
				}
			}
		},
		component: () => import("../views/vendor/create-device")
	}
]


export default routes
