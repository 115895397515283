import axios from 'axios';

export const state = {
    site: null
}


export const actions = {
    site_details ({ commit }) {
        const site_id = sessionStorage.getItem('site_id')
        axios({
            method: 'GET',
            url: '/api/v1/app/stp-automation/site',
            params: {
                id: site_id
            }
        })
        .then((response) => {
            if(response.data.errCode == -1){
                commit('SET_CURRENT_SITE', response.data.data)
                return response.data.data;      
            }    
        })
        .catch((err) => {
            console.log(err)
        })
    }
};


export const getters = {
    get_site_details(state){
        return state.site
    }
}

export const mutations = {
    SET_CURRENT_SITE(state, newValue) {
        state.site = newValue
    }
}

